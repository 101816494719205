import React, { useEffect } from 'react';

const Video = () => {

  return (
    <>

    <section
      className="section has-dots is-full"
      style={{ backgroundImage: `url(${require('../../../images/backgrounds/section-dots-bg.svg')})` }}>
      <div className="v-space-xxlg"></div>
      <div className="container is-full">
        <header className="section-header isclsr">
          <h1 className="title">Confira o vídeo da campanha</h1>
        </header>
        <div className="yt-video f-h-center is-large">
          <iframe
            width="560"
            height="315"
            src={'https://www.youtube.com/embed/JJJ7CsSet_o'}
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </div>
      </div>
    </section>
    </>
  );
};

export default Video;