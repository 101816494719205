import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Modal } from '../lib';

const useModal = (config) => {
  const { defaultOpen } = config || {};

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (defaultOpen !== undefined) setIsOpen(defaultOpen);
    return () => setIsOpen(false);
  }, []);

  const toggle = (state) => {
    if (typeof state !== 'boolean') state = !isOpen;
    setIsOpen(state);
  };

  const ModalComponent = ({ children, onClickToClose, ...rest }) =>
    isOpen ? (
      <Modal
        isOpen
        onClickToClose={() => {
          setIsOpen(false);
          if (onClickToClose) onClickToClose();
        }}
        {...rest}>
        {children}
      </Modal>
    ) : null;

  ModalComponent.propTypes = {
    children: PropTypes.any,
    onClickToClose: PropTypes.func,
  };

  return [ModalComponent, toggle];
};

export default useModal;
