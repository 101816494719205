import { useEffect, useState } from 'react';

import * as firebase from 'firebase/app';
import 'firebase/firestore';

const useOverview = () => {

  const [status, setStatus] = useState('waiting');
  const [response, setResponse] = useState(null);

  let db = null;

  useEffect(() => {
    db = firebase.firestore();
    setStatus('waiting');
    return () => {
      setStatus('waiting');
      setResponse(null);
    };
  }, []);

  const call = {
    fetch: () => {
      setStatus('loading');
      return new Promise((resolve, reject) => {
        db.collection('overview').doc('counters').get()
        .then((snapshot) => {
          const data = snapshot.data();
          setResponse(data);
          setStatus('success');
          resolve(data);
        })
        .catch((error) => {
          setResponse({});
          setStatus('error');
          reject(error);
        });
      })
    }
  };

  return [call, { response, status }];

};

export default useOverview;
