import React, { useEffect } from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

import Hero from '../components/partials/home/Hero';
import Numbers from '../components/partials/home/Numbers';
import Sections from '../components/partials/home/Sections';
import Video from '../components/partials/home/Video';
import Institutions from '../components/partials/home/Institutions';
import Ambassadors from '../components/partials/home/Ambassadors';
import Ranking from '../components/partials/home/Ranking';

const IndexPage = () => {

  // useEffect(() => {
  //   setTimeout(() => {
  //     document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
  //       anchor.addEventListener('click', (event) => {
  //         event.preventDefault();
  //         document.querySelector(anchor.getAttribute('href')).scrollIntoView({
  //           behavior: 'smooth',
  //           block: 'start'
  //         });
  //       });
  //     });
  //   }, 130);
  // }, []);

  return (
    <Layout>

      <SEO title="Início" />

      <Hero />
      <Numbers />
      <Sections />
      <Video />
      <Institutions />
      <Ambassadors />
      {/* <Ranking /> */}

    </Layout>
  );

};

export default IndexPage;
