import React, { useEffect } from 'react';

import useOverview from '../../hooks/useOverview';

import IMask from 'imask';

const mask = IMask.createMask({
  mask: 'num',
  blocks: {
    num: {
      mask: Number,
      scale: 0,
      thousandsSeparator: '.',
      min: 0,
      max: 10000000,
    },
  },
});

const Numbers = () => {

  const [{ fetch }, { response }] = useOverview();

  let { institutions = 0, donations = 0, children = 0 } = response || {};

  useEffect(() => {
    fetch();
  }, [])

  institutions = mask.resolve(institutions + '');
  donations = mask.resolve(donations + '');
  children = mask.resolve(children + '');
  
  return (
    <section
      id="numeros"
      className="section is-dark has-dots"
      style={{ backgroundImage: `url(${require('../../../images/backgrounds/section-dots-light-bg.svg')})` }}>

      <div className="container is-medium">

        <header className="section-header">
          <h1 className="title is-xxlarge is-white">Sobre a campanha</h1>
          <div className="v-space-lg"></div>
          <p className="text is-white">
            Pensando em propagar #atitudesdobem, a Malwee criou essa campanha para que você possa conhecer e se engajar com o trabalho de ONGs incríveis que vêm atuando com crianças em todo o Brasil. 
          </p>
          <p className="text is-white">
            Para apoiá-las, basta cadastrar a sua etiqueta e escolher a ONG que você quer ajudar. A doação é por conta da Malwee!
          </p>
          <p className="text is-white">
            Curtiu? Então escolha a sua ONG e compartilhe #atitudesdobem
          </p>
        </header>

        <div className="columns is-multiline f-h-center">
          <div className="column is-one-third-desktop is-center-mobile">
            
            <div className="media">
              <div className="media-left">
                <figure
                  className="image is-icon"
                  style={{ backgroundImage: `url(${require('../../../images/icons/icon-tshirt.svg')})` }}>
                  <img src={require('../../../images/icons/icon-tshirt.svg')} alt="Image Placeholder" />
                </figure>
              </div>
              <div className="media-content">
                <p className="text is-xlarge is-white is-700">
                  {donations}
                </p>
                <p className="text is-size-4 is-lighter is-uppercase is-300">
                  Número de ações do bem realizadas
                </p>
                <span className="line-content"></span>
              </div>
            </div>

          </div>
          <div className="column is-one-third-desktop is-center-mobile">
            
            <div className="media">
              <div className="media-left">
                <figure
                  className="image is-icon"
                  style={{ backgroundImage: `url(${require('../../../images/icons/icon-hand.svg')})` }}>
                  <img src={require('../../../images/icons/icon-hand.svg')} alt="Image Placeholder" />
                </figure>
              </div>
              <div className="media-content">
                <p className="text is-xlarge is-white is-700">
                  {institutions}
                </p>
                <p className="text is-size-4 is-lighter is-uppercase is-300">
                  ONGs
                </p>
                <span className="line-content"></span>
              </div>
            </div>

          </div>
          <div className="column is-one-third-desktop is-center-mobile">
            
            <div className="media">
              <div className="media-left">
                <figure
                  className="image is-icon"
                  style={{ backgroundImage: `url(${require('../../../images/icons/icon-kids.svg')})` }}>
                  <img src={require('../../../images/icons/icon-kids.svg')} alt="Image Placeholder" />
                </figure>
              </div>
              <div className="media-content">
                <p className="text is-xlarge is-white is-700">
                  {children}
                </p>
                <p className="text is-size-4 is-lighter is-uppercase is-300">
                  Crianças atendidas
                </p>
                <span className="line-content"></span>
              </div>
            </div>

          </div>
        </div>

      </div>

    </section>
  );
};

export default Numbers;