import React from 'react';
import { Link } from 'gatsby';

const Hero = () => {
  return (
    <>

      <section
        className="hero is-medium visible-desktop-true has-mask"
        style={{ backgroundImage: `url(${require('../../../images/backgrounds/hero-bg.jpg')})` }}>
      
        <div className="hero-body">
          <div className="container">
            <div className="columns is-gapless is-vcentered">
              <div className="column is-7-desktop f-h-center f-p-relative">

                <img src={require('../../../images/pics/hero-obj-left.png')} alt="" className="hero-obj-left"/>
                <img src={require('../../../images/pics/hero-obj-right.png')} alt="" className="hero-obj-right"/>

                <h1>
                  <figure
                    className="image is-logo-text-lg f-h-center"
                    style={{ backgroundImage: `url(${require('../../../images/logos/logoatitudesdobem.png')})` }}>
                    <img src={require('../../../images/logos/logoatitudesdobem.png')} alt="Atitudes do Bem" />
                  </figure>
                </h1>

                <div className="v-space"></div>

                <h2 className="title is-x-large">
                  Doações encerradas!
                </h2>
                <div className="v-space"></div>
                <p className="text is-size-4 has-simple-lh">
                  Foram 28.675 códigos de etiquetas cadastradas,
                  <br/>
                  <span className="is-gold has-simple-lh">
                    R$860.250,00 serão doados
                  </span>
                  <br/>
                  para as ONGs participantes da campanha!
                </p>
                <div className="v-space-lg"></div>
                <p className="text is-size-4 has-simple-lh">
                  <span className="is-gold has-simple-lh">
                    As 18.973 crianças atendidas
                  </span>
                  <br/>
                  pelas 20 ONGs apoiadas pelo projeto agradecem!
                </p>

                {/* <p className="text is-white is-size-4">
                  <strong>1 peça comprada e cadastrada no site = 1 doação da Malwee </strong>
                  para uma ONG escolhida por você.
                </p>
                <div className="v-space"></div>
                <p className="text is-white is-size-4">
                  Neste fim de ano, junte-se a nós na maior campanha de doação já realizada pela Malwee para apoiar diversas ONGs que trabalham diretamente com crianças.
                </p>
                <div className="v-space"></div>
                <p className="text is-white is-size-4">
                  A Malwee vai doar o valor equivalente a uma camiseta para cada peça comprada e cadastrada no site das coleções Alto Verão e Celebration das marcas Malwee, Malwee Kids e Malwee Liberta.
                </p>
                <div className="v-space"></div>
                <p className="text is-white is-size-4">
                  <strong>
                    Você escolhe quais organizações vamos apoiar!
                  </strong>
                </p>
                <div className="v-space-lg"></div>
                <Link to="instituicoes" className="button is-outlined is-white">
                  <span>Escolha a ONG</span>
                </Link> */}
              </div>
            </div>
          </div>
        </div>
      </section> 

      <section
        className="hero is-mobile has-mask visible-desktop-false"
        style={{ backgroundImage: `url(${require('../../../images/backgrounds/hero-bg.jpg')})` }}>

        <div className="hero-body">
          <div className="container">
            <div className="columns is-gapless is-vcentered">
              <div className="column is-7-desktop f-h-center f-p-relative">

                <img src={require('../../../images/pics/hero-obj-left.png')} alt="" className="hero-obj-left"/>
                <img src={require('../../../images/pics/hero-obj-right.png')} alt="" className="hero-obj-right"/>

                <h1>
                  <figure
                    className="image is-logo-text-lg f-h-center"
                    style={{ backgroundImage: `url(${require('../../../images/logos/logoatitudesdobem.png')})` }}>
                    <img src={require('../../../images/logos/logoatitudesdobem.png')} alt="Atitudes do Bem" />
                  </figure>
                </h1>

                <div className="v-space"></div>

                <h2 className="title is-x-large">
                  Doações encerradas!
                </h2>
                <div className="v-space"></div>
                <p className="text is-size-4 has-simple-lh">
                  Foram 28.675 códigos de etiquetas cadastradas,
                  <br/>
                  <span className="is-gold has-simple-lh">
                    R$860.250,00 serão doados
                  </span>
                  <br/>
                  para as ONGs participantes da campanha!
                </p>
                <div className="v-space-lg"></div>
                <p className="text is-size-4 has-simple-lh">
                  <span className="is-gold has-simple-lh">
                    As 18.973 crianças atendidas
                  </span>
                  <br/>
                  pelas 20 ONGs apoiadas pelo projeto agradecem!
                </p>

              </div>
            </div>
          </div>
        </div>

      </section> 

    </>
  );
};

export default Hero;